<template>
	<div>
		<van-cell-group class="from-group-item" style="margin-bottom: 10px;">
			<div class="goods-container">
				<div class="goods-wrap">
					<van-cell title-class="top-cell flex flex-row">
						<template slot="title">
							<van-image width="3rem" height="3rem" :src="goods_info.images && goods_info.images.length > 0 ? goods_info.images[0] : ''" fit="cover">
								<template v-slot:loading>
									<van-loading type="spinner" size="20" />
								</template>
							</van-image>

							<div>
								<div style="width: 6rem;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
									{{goods_info.moldbaby_name}}
								</div>
								<div style="width: 6rem;height: 2rem;overflow: hidden;text-overflow:ellipsis;">
									<span class="text-base" style="overflow: hidden;text-overflow:ellipsis;color: gray;">{{goods_info.moldbady_desc}}</span>
								</div>
								<div class="price-wrap clamp-1">
									<span class="text-theme1" style="color: #f6a307;font-size: 15px;margin-right: 2px;">￥{{goods_info.real_price}}</span>
									<span class="text-grey" style="text-decoration: line-through;">￥{{goods_info.retail_price}}</span>
								</div>
							</div>
						</template>
					</van-cell>
				</div>
			</div>
		</van-cell-group>

		<van-cell-group class="from-group-item" style="margin-bottom: 10px;">
			<van-field :value="'￥'+goods_info.deposit_price" error input-align="right" autosize label="支付定金" type="text" />
		</van-cell-group>
		<van-row type="flex" justify="center" class="from-bottom-button">
			<van-col span="6" style="margin: 10px 0;">
				<van-button type="info" @click="pay" :loading="commit_loading" loading-text="支付中...">立即支付</van-button>
			</van-col>
		</van-row>
	</div>
</template>

<script>
	import {
		Field,
		Row,
		Col,
		Cell,
		CellGroup,
		Picker,
		Popup,
		DatetimePicker,
		Uploader,
		SwitchCell,
		Checkbox,
		Button,
		Toast,
		Stepper,
		Slider,
		Collapse,
		CollapseItem,
		Loading
	} from "vant";
	import {
		user_marketing_hot_goodsdetail, //爆款详情
		user_marketing_hot_createorder, //创建订单
		user_marketing_hot_buildorder, //构造订单
		live_order_pay, //支付订单
		activity_detail
	} from "@/request/api";
	import wx from "@/request/utils/wechat"; //微信JSSDK

	export default {
		components: {
			[Field.name]: Field,
			[Row.name]: Row,
			[Col.name]: Col,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Picker.name]: Picker,
			[Popup.name]: Popup,
			[DatetimePicker.name]: DatetimePicker,
			[Uploader.name]: Uploader,
			[SwitchCell.name]: SwitchCell,
			[Checkbox.name]: Checkbox,
			[Button.name]: Button,
			[Stepper.name]: Stepper,
			[Slider.name]: Slider,
			[Collapse.name]: Collapse,
			[CollapseItem.name]: CollapseItem,
			[Loading.name]: Loading
		},
		data() {
			return {
				collapse_address: ["1"],
				commit_loading: false,
				address: "",
				goods_id: 0,
				goods_info: false,
				activity_id: '',
				is_enroll: 0
			};
		},
		mounted: function() {
			this.goods_id = this.$route.query.id;
			this.activity_id = this.$route.query.activity_id
			this.initData()
		},
		methods: {
			initData() {
				user_marketing_hot_goodsdetail({
						rid: this.goods_id,
						aid: this.activity_id
					})
					.then(res => {
						if (res.code == 1) {
							this.goods_info = res.data;
						} else {
							this.$dialog({
								title: "提示",
								message: res.msg
							}).then(g => {
								this.$router.go(-1);
							});
						}
					})
					.catch(error => {
						this.$dialog({
							title: "提示",
							message: "访问出错"
						}).then(g => {
							this.$router.go(-1);
						});
					});
			},
			pay() {
				let _this = this
				user_marketing_hot_createorder({
					rid: this.goods_info.activity_id,
					id: this.goods_info.id,
				}).then(res => {
					if (res.code == 1){
						_this.$router.replace({
							path: '/payment/method',
							query: {
								id: res.data.id,
								type: 'hot'
							}
						})
					}else{
						this.$dialog({
							title: "提示",
							message: res.msg
						})
					}
					
				});
			}
		}
	};
</script>

<style scoped lang="less">
	// 公共商品样式
	.goods-wrap {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background-color: white;
		font-size: 0;
		overflow: hidden;

		.van-image {
			margin-right: 10px;
			border-radius: 5%;
		}

		.text-wrap {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			height: 3rem;
			flex: 1;

			font-size: 0;

			span {
				font-size: 12px;
			}

			.brand-wrap {
				flex: 1;
				// height: 17px;
			}

			.name-wrap {
				flex: 1;

				// height: 17px;
				.text-base {
					// font-size: 14px;
					// font-weight: bold
				}
			}

			.desc-wrap {
				// height: 35px;
				flex: 2;
				margin-bottom: 5px;
			}

			.price-wrap {
				// height: 17px;
				flex: 1;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;

				.text-grey {
					text-decoration: line-through;
				}

				.text-theme3 {
					display: block;
					font-size: 18px;
					// margin-left: 5px;
				}
			}
		}
	}
</style>
